import styled from "styled-components";

const Paragraph = styled.p`
    margin: 50px 0;
    color: #696969;
    font-size: 16px;
    line-height: 184.69%;
    letter-spacing: 0.03em;  
`;

export default Paragraph;