import styled from "styled-components";
import { Link } from 'gatsby';

export const FlowerCakeContainer = styled.div`
    width: 80%;
    margin: 20px auto;
    line-height: 1.8;
`;

export const ProjectTable = styled.table`
    width: 100%;
    text-align: left;

    th {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.03em;
        padding-bottom: 20px;
    }
    td {
        font-size: 14px;
        letter-spacing: 0.03em;
    }
`;

export const BoldField = styled.td`
    a {
        font-size: 16px;
        letter-spacing: 0.03em;
        font-weight: 900;
        color: #dd7834;
        border-bottom: 2px solid transparent;
        transition: 0.3s;

        :hover {
            border-bottom: 2px solid #dd7834;
        }
    }
`;

export const Image = styled.img`
    margin: 50px 0;
    width: 95%;
    border-radius: 5px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, .5);
`;

export const TextContainer = styled.div`
    max-width: 800px;
`;

export const MiddleContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const LeftImage = styled(Image)`
    width: 40%;
    height: 600px;
    margin: 0 100px 20px 20px;

    @media screen and (max-width: 800px) {
        width: 100%;
        height: 500px;
        margin: 0;
    }

`;

export const RightContent = styled.div`
    width: 50%;
    
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 30px 100px;
   
   a { 
        text-decoration:none;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: black;
        font-weight: 900;
        border-bottom: 2px solid transparent;
        transition: 0.3s;
        :hover {
            border-bottom: 2px solid black;
        }
   }
   span {
        font-size: 20px;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const CodeLink = styled.div`
    width: 100%;
    text-align: center;
    margin: 10px auto;
`;

export const SiteLink = styled(CodeLink)``;
