import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import Paragraph from '../components/Paragraph';

import AppImg from '../Images/FlowerCake1.png';
import StoreImg from '../Images/FlowerCakeStore.png';
import MiniCartImg from '../Images/FlowerCakeMiniCart.png';

import {
    FlowerCakeContainer,
    ProjectTable,
    BoldField,
    Image,
    TextContainer,
    MiddleContentContainer,
    LeftImage,
    RightContent,
    LinksContainer,
    CodeLink,
    SiteLink
} from '../Styles/FlowerCake.style';
 
const NotFound = () => { 
	return (
		<Layout>
			<Head title="Forecast" />
            <FlowerCakeContainer>
                <TextContainer>
                    <h1>Flower Cake</h1>
                    <Paragraph>
                        Flower Cake is my first E-Commerce website created with React and Redux. To me this project was an excellent 
                         opportunity to hone my Redux skills and improve my abilities as a frontend developer.
                        The website consists of multiple pages, including a Home page, an About page,
                        a Store page, a Favorites page, and a Checkout page. All designed to offer a clean looking
                        and intuitive user experience. 
                    </Paragraph>
                </TextContainer>

                {/* <ProjectTable>
                    <tr>
                        <th>Type</th>
                        <th>Stack</th>
                        <th>Main</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <td>Personal</td>
                        <td>React</td>
                        <BoldField><a>Repository</a></BoldField>
                        <BoldField><a>Live Site</a></BoldField>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Netlify</td>
                    </tr>
                </ProjectTable> */}

                <Image src={AppImg}/>

                <TextContainer>
                    <h3>Project Purpose and Goal</h3>
                    <Paragraph>
                        The main purpose of this project was to practice and showcase my skills in Redux,
                         a popular state management library for React. By building an e-commerce website such as Flower Cake,
                         I was able to gain hands-on experience in implementing Redux in a real-world application.
                         Through this project, I was able to demonstrate my understanding of the core concepts of Redux, such as actions, reducers, and the store.
                        I spend a good amount of time making sure I was using
                        the best practices of state management and data
                        manipulation. Besides this, I also wanted to make
                        this e-commerce website looks nice so real people would enjoy using,
                        meaning it had to to offer a unique experience, with a clean and intuitive
                        layout that guides the user through the different sections of the website.
                    </Paragraph>
                </TextContainer>

                <MiddleContentContainer>
                    <LeftImage src={MiniCartImg}/>
                    <RightContent>
                        <h3>Problems and Thought Process</h3>
                        <Paragraph>
                        While working on Flower Cake, I encountered several challenges 
                        that required careful thought and problem-solving skills. 
                        One particular issue that I faced was ensuring a seamless and intuitive 
                        user experience throughout the shopping process. To address this, 
                        I decided to add a mini cart feature that displays the items in the user's 
                        cart as they continue shopping. The thought process behind this decision 
                        involved identifying the potential problem of users accidentally leaving 
                        items behind or losing track of what they had added to their cart. 
                        By adding a mini cart, I could offer users a quick and easy way to keep 
                        track of their purchases and ensure that they don't have to start over 
                        if they accidentally navigate away from the cart page.
                        </Paragraph>
                    </RightContent>
                </MiddleContentContainer>

                <TextContainer>
                    <h3>Future Improvements</h3>
                    <Paragraph>
                        One feature I have in mind and I wish to add to this website is search functionality.
                        I would implement a robust search feature that allows users to search for cakes by name, flavor,
                        or category. This could involve integrating a search bar on the home or store page and optimizing 
                        search results for faster loading time. In addition to search functionality, I also considered enhancing
                        the user experiece, implementing a ratings and reviews system, and also make the website more accessible by 
                        implementing accessibility features.
                    </Paragraph>
                </TextContainer>
                
                <Image src={StoreImg}/>

                <LinksContainer>
                    <CodeLink>
                        <a
                                title='Flower Cake'
                                target='_blank'
                                rel='noreferrer'
                                href='https://github.com/shadencodes/flower-cake'
                            >
                               
                                View Code <span>&#8250;</span>
                                
                            </a> 
                    </CodeLink>
                    <SiteLink>
                            <a
                                title='Flower Cake'
                                target='_blank'
                                rel='noreferrer'
                                href='https://flower-cake.vercel.app'
                            >
                               
                                Live Site <span>&#8250;</span>
                                
                            </a>                        
                    </SiteLink>
                </LinksContainer>
            </FlowerCakeContainer>
		</Layout>
	);
}

export default NotFound;
